import { useRef, useState, useEffect } from "react";
import "./AboutMe.css";

function AboutMe({ isVisible, setIsVisible, isValue, setValue }) {
    const [aboutMe, setAboutMe] = useState("");
    const shadeRef = useRef(null);

    useEffect(() => {
        setAboutMe(isValue ? isValue : "");
    }, [isValue]);

    return (
        <div
            className="d-flex justify-content-center align-items-center"
            ref={shadeRef}
            onClick={(e) => {
                if (e.target === shadeRef.current) {
                    setIsVisible(false);
                }
            }}
            style={{
                position: "fixed",
                top: "0",
                left: "0",
                width: "100vw",
                height: "100vh",
                background: "rgba(0,0,0,0.5)",
                zIndex: "999",
                pointerEvents: isVisible ? "auto" : "none",
                opacity: isVisible ? "1" : "0",
            }}
        >
            <div
                style={{
                    width: "672px",
                    background: "#fff",
                    padding: "24px",
                    borderRadius: "32px",
                    fontWeight: "bold",
                }}
            >
                <h1
                    className="m-0"
                    style={{
                        fontSize: "20px",
                        fontWeight: "bold",
                        lineHeight: "32px",
                        position: "relative",
                        paddingBottom: "32px",
                    }}
                >
                    About me
                </h1>
                <div style={{ padding: "0 16px" }}>
                    <textarea
                        className="scroll-content"
                        placeholder="ex) Hi, I'm Sally! I studied Biology at the University of British Columbia and now I'm diving into Pharm.D. My vet experience helps me simplify science for high school and younger students. Let's explore biology together!"
                        style={{
                            width: "100%",
                            height: "120px",
                            borderRadius: "20px",
                            padding: "16px",
                            resize: "none",
                            border: "none",
                            background: "#f7f7f7",
                            marginBottom: "24px",
                        }}
                        onChange={(e) => {
                            const value = e.target.value;
                            setAboutMe(value);
                        }}
                        value={aboutMe}
                    />
                    <div
                        className="text-start"
                        style={{ fontSize: "14px", fontWeight: "600" }}
                    >
                        <b>Tip: </b>Share your teaching philosophy,
                        qualifications, and what drives your passion for
                        education. Keep the focus on presenting yourself as an
                        engaging and qualified educator, leaving specific lesson
                        information for the dedicated lesson section.
                    </div>
                    <div
                        className="d-flex justify-content-around align-items-center"
                        style={{
                            marginTop: "32px",
                        }}
                    >
                        <div
                            className="d-flex justify-content-center align-items-center"
                            style={{
                                width: "115.53px",
                                height: "48px",
                                fontWeight: "600",
                                borderRadius: "22px",
                                cursor: "pointer",
                            }}
                            onClick={() => {
                                setIsVisible(false);
                            }}
                        >
                            Cancel
                        </div>
                        <div
                            className="d-flex justify-content-center align-items-center"
                            style={{
                                width: "115.53px",
                                height: "48px",
                                background: "#35b234",
                                borderRadius: "22px",
                                fontWeight: "600",
                                color: "#fff",
                                cursor: "pointer",
                            }}
                            onClick={() => {
                                if (aboutMe.trim()) {
                                    setValue(aboutMe.trim());
                                    setIsVisible(false);
                                }
                            }}
                        >
                            Add
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AboutMe;

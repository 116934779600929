import {
    faCircleQuestion,
    faComment,
    faUser,
    faMagnifyingGlass,
    faLocationDot,
    faBook,
    faChalkboardTeacher,
    faGlobe,
    faLocation,
    faLanguage,
    faMale,
    faQuestion,
    faDollarSign,
    faGraduationCap,
    faAngleRight,
    faAngleLeft,
    faX,
    faAngleDown,
    faAngleUp,
    faEnvelope,
    faCircleUser,
    faCirclePlus,
    faPen,
    faArrowLeft,
    faCircleCheck,
    faCamera,
    faPaperPlane,
    faCommentDots,
    faBuildingColumns,
} from "@fortawesome/free-solid-svg-icons";

export default function getIcons() {
    return {
        faCircleQuestion,
        faComment,
        faUser,
        faMagnifyingGlass,
        faLocationDot,
        faBook,
        faChalkboardTeacher,
        faGlobe,
        faLocation,
        faLanguage,
        faMale,
        faQuestion,
        faDollarSign,
        faGraduationCap,
        faAngleRight,
        faAngleLeft,
        faX,
        faAngleDown,
        faAngleUp,
        faEnvelope,
        faCircleUser,
        faCirclePlus,
        faPen,
        faArrowLeft,
        faCircleCheck,
        faCamera,
        faPaperPlane,
        faCommentDots,
        faBuildingColumns,
    };
}

const languageList = [
    "English",
    "French",
    "Cantonese",
    "Mandarin",
    "Hindustani",
    "Spanish",
    "Punjabi",
    "Arabic",
    "Tagalog",
    "Italian",
    "German",
    "Portuguese",
    "Persian",
    "Russian",
    "Tamil",
    "Vietnamese",
    "Gujarati",
    "Polish",
    "Korean",
    "Serbo-Croatian",
    "Greek",
    "Haitian Creole",
    "Ukrainian",
    "Bengali",
    "Romanian",
    "Dutch",
    "Cree",
    "Japanese",
    "Hebrew",
    "Turkish",
    "Malayalam",
    "Hungarian",
    "Ilocano",
    "Somali",
    "Swahili",
    "Telugu",
];

export default languageList;
